import { ContractVariationPercentageLine } from '@types'

export class BudgetLinesContractVariation {
	budgetLineId?: number
	budgetItemId: number
	budgetSubItemId: number
	percentage: number
	amount: number

	constructor(data: BudgetLinesContractVariation | ContractVariationPercentageLine) {
		Object.assign(this, data)
	}
}
