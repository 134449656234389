import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useNavigate } from 'react-router-dom'
import { PlusIcon } from '@icons'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { FC, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { mandaysRegisterService } from '@services'
import { MandaysRegisterFormModel } from '@types'
import RVTextField from '@components/layout/RVTextField'
import AutocompleteSingleselect from '@components/layout/AutocompleteMultiselect'
import { Autocomplete, Grid, TextField } from '@mui/material'

const MandaysRegisterCreate: FC = () => {
	const navigate = useNavigate()

	const [isSyncing, setIsSyncing] = useState(false)

	const [open, setOpen] = useState(false)

	const { t } = useTranslation()

	const formikValidationSchema = Yup.object().shape({
		projectNo: Yup.string().required(t('Project number is required')),
		year: Yup.object().nullable(true).required(t('Year is required')),
		weekNumbers: Yup.object().nullable(true)
	})

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const currentYear = new Date().getFullYear()

	const yearList = [
		{ label: currentYear.toString(), value: currentYear },
		{ label: (currentYear - 1).toString(), value: currentYear - 1 }
	]

	const weekNumberList = [
		{ label: '1', value: 1 },
		{ label: '2', value: 2 },
		{ label: '3', value: 3 },
		{ label: '4', value: 4 },
		{ label: '5', value: 5 },
		{ label: '6', value: 6 },
		{ label: '7', value: 7 },
		{ label: '8', value: 8 },
		{ label: '9', value: 9 },
		{ label: '10', value: 10 },
		{ label: '11', value: 11 },
		{ label: '12', value: 12 },
		{ label: '13', value: 13 },
		{ label: '14', value: 14 },
		{ label: '15', value: 15 },
		{ label: '16', value: 16 },
		{ label: '17', value: 17 },
		{ label: '18', value: 18 },
		{ label: '19', value: 19 },
		{ label: '20', value: 20 },
		{ label: '21', value: 21 },
		{ label: '22', value: 22 },
		{ label: '23', value: 23 },
		{ label: '24', value: 24 },
		{ label: '25', value: 25 },
		{ label: '26', value: 26 },
		{ label: '27', value: 27 },
		{ label: '28', value: 28 },
		{ label: '29', value: 29 },
		{ label: '30', value: 30 },
		{ label: '31', value: 31 },
		{ label: '32', value: 32 },
		{ label: '33', value: 33 },
		{ label: '34', value: 34 },
		{ label: '35', value: 35 },
		{ label: '36', value: 36 },
		{ label: '37', value: 37 },
		{ label: '38', value: 38 },
		{ label: '39', value: 39 },
		{ label: '40', value: 40 },
		{ label: '41', value: 41 },
		{ label: '42', value: 42 },
		{ label: '43', value: 43 },
		{ label: '44', value: 44 },
		{ label: '45', value: 45 },
		{ label: '46', value: 46 },
		{ label: '47', value: 47 },
		{ label: '48', value: 48 },
		{ label: '49', value: 49 },
		{ label: '50', value: 50 },
		{ label: '51', value: 51 },
		{ label: '52', value: 52 },
		{ label: '53', value: 53 },
		{ label: '54', value: 54 }
	]

	return (
		<>
			<div>
				<Button
					onClick={handleClickOpen}
					color="primary"
					startIcon={<PlusIcon fontSize="small" />}
					sx={{ m: 1 }}
					variant="contained">
					{t('Nieuw verzoek')}
				</Button>
				<Formik
					enableReinitialize={true}
					validationSchema={formikValidationSchema}
					initialValues={{
						projectNo: '',
						year: yearList[0],
						weekNumbers: []
					}}
					onSubmit={async (values): Promise<void> => {
						try {
							const formValues = {
								projectNo: values.projectNo,
								year: values.year.value,
								weekNumbers: values.weekNumbers.map((weekNumber) => {
									return weekNumber.value
								})
							}

							setIsSyncing(true)
							mandaysRegisterService
								.createRegister(new MandaysRegisterFormModel(formValues))
								.then(() => {
									const successMessage = t('Work Order Created!')
									console.log('Success message: ' + successMessage)
									toast.success(successMessage)
									setIsSyncing(false)
									handleClose()
									window.location.reload()
								})
								.catch((error) => {
									setIsSyncing(false)
									console.log('Manday register service POST call error', error)
									toast.error(t('Something went wrong!'))
								})
						} catch (error) {
							setIsSyncing(false)
							console.error(error)
							const errMessage = t('Something went wrong!')
							toast.error(errMessage)
							handleClose()
							navigate('/mandaysRegister')
						}
					}}>
					{({ handleSubmit, values, isSubmitting, setFieldValue, dirty, isValid }): JSX.Element => (
						<Dialog open={open} onClose={handleClose}>
							<DialogTitle>{t('new request')}</DialogTitle>
							<form noValidate onSubmit={handleSubmit}>
								<DialogContent>
									<Grid container spacing={2}>
										<Grid item md={6} xs={12}>
											<RVTextField
												exactFieldName="projectNo"
												label={t('projectNo')}
												onChange={(e) => {
													setFieldValue('projectNo', e.target.value)
												}}
												value={values.projectNo}
											/>
										</Grid>
										<Grid item md={6} xs={12}>
											<AutocompleteSingleselect
												options={yearList}
												id="year"
												exactFieldName="year"
												optionLabel={t('year')}
												value={values.year || null}
												required={true}
												defaultValue={yearList[0]}
												onChange={(e) => {
													setFieldValue('year', e)
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<Autocomplete
												multiple={true}
												options={weekNumberList}
												id="weekNumbers"
												freeSolo={true}
												value={values.weekNumbers || null}
												onChange={(e, value) => {
													setFieldValue('weekNumbers', value)
												}}
												isOptionEqualToValue={(option, value) => option.value === value.value}
												renderInput={(params) => (
													<TextField
														{...params}
														variant="standard"
														label={t('weekNumbers')}
														placeholder="Favorites"
														error={values.weekNumbers.length === 0}
														helperText={values.weekNumbers.length === 0 && t('Week numbers are required')}
													/>
												)}
											/>
										</Grid>
									</Grid>
								</DialogContent>
								<DialogActions>
									<Button onClick={handleClose}>{t('cancel')}</Button>
									<LoadingButton
										color="primary"
										disabled={!(dirty && isValid && values.weekNumbers.length > 0)}
										loading={isSubmitting || isSyncing}
										type="submit"
										variant="contained">
										{t('Save')}
									</LoadingButton>
								</DialogActions>
							</form>
						</Dialog>
					)}
				</Formik>
			</div>
		</>
	)
}

export default MandaysRegisterCreate
