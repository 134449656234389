export const gtmConfig: { containerId: string } = {
	containerId: process.env.REACT_APP_GTM_CONTAINER_ID
}

export const authConfig: { clientId: string; authority: string; web_uri: string } = {
	clientId: process.env.REACT_APP_CLIENT_ID,
	authority: process.env.REACT_APP_AUTHORITY,
	web_uri:
		!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
			? process.env.REACT_APP_WEB_URI_DEV
			: process.env.REACT_APP_WEB_URI_PROD
}
