import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'react-quill/dist/quill.snow.css'
import 'nprogress/nprogress.css'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import App from './App'
import { AuthProvider } from './contexts/AuthContext'
import { SettingsProvider } from './contexts/SettingsContext'
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from '@azure/msal-browser'
import { msalConfig } from '@utils'
import ScrollToTop from '@utils/scrollToTop'

export const msalInstance = new PublicClientApplication(msalConfig)

const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
	msalInstance.setActiveAccount(accounts[0])
}

msalInstance.addEventCallback((event: EventMessage) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
		const payload = event.payload as AuthenticationResult
		const account = payload.account
		msalInstance.setActiveAccount(account)
	}
})

ReactDOM.render(
	<StrictMode>
		<HelmetProvider>
			<StyledEngineProvider injectFirst>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<SettingsProvider>
						<BrowserRouter>
							<div>
								<ScrollToTop />
								<AuthProvider>
									<App pca={msalInstance} />
								</AuthProvider>
							</div>
						</BrowserRouter>
					</SettingsProvider>
				</LocalizationProvider>
			</StyledEngineProvider>
		</HelmetProvider>
	</StrictMode>,
	document.getElementById('root')
)
