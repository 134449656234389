import React, { FC } from 'react'
import { Autocomplete, AutocompleteInputChangeReason, TextField } from '@mui/material'
import { AutocompleteShape } from '@types'
import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'

export interface AutocompleteSingleselectProps {
	options: AutocompleteShape[]
	id: string
	exactFieldName: string
	optionLabel: string
	onInputChange?: (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => void
	onChange?: (value: any) => void
	required?: boolean
	disabled?: boolean
	useKeyAsLabel?: boolean
	dependentFieldNames?: string[]
	value?: any
	defaultValue?: any
	disableClearable?: boolean
	size?: string
	isOptionEqualToValue?: (option, value) => void
}

const AutocompleteSingleselect: FC<AutocompleteSingleselectProps> = (props) => {
	const {
		options,
		id,
		exactFieldName,
		optionLabel,
		useKeyAsLabel,
		required,
		disabled,
		dependentFieldNames,
		value,
		defaultValue,
		disableClearable,
		size,
		onChange,
		onInputChange
	} = props
	const { values, errors, touched, setFieldValue, setFieldTouched, handleBlur, handleChange } = useFormikContext()

	const onChangeHandler = (event, value): void => {
		dependentFieldNames.forEach((fieldName) => {
			switch (typeof values[fieldName]) {
				case 'object':
					return setFieldValue(fieldName, null)

				case 'string':
				case 'number':
				case 'boolean':
				default:
					return setFieldValue(fieldName, '')
			}
		})
		setFieldValue(exactFieldName, value)

		if (typeof onChange === 'function') {
			onChange(value)
		}
	}

	return (
		<>
			<Autocomplete
				id={id}
				options={options}
				getOptionLabel={(option) => (useKeyAsLabel ? option.value : option.label || '')}
				onInputChange={onInputChange}
				onChange={onChangeHandler}
				selectOnFocus
				value={value}
				defaultValue={defaultValue}
				openOnFocus
				clearOnBlur
				autoComplete
				autoHighlight
				onBlur={handleBlur}
				onTouchEnd={handleChange}
				size={size ? 'medium' : 'small'}
				disabled={disabled}
				disableClearable={disableClearable}
				filterSelectedOptions
				renderOption={(props, option) => {
					return (
						<li {...props} key={Math.random()}>
							{option.label}
						</li>
					)
				}}
				isOptionEqualToValue={(option, value) => {
					if (value === '' || !option || !value || typeof value === 'string') {
						return option === options[0]
					}
					return option.value === value.value
				}}
				renderInput={(params): JSX.Element => (
					<TextField
						{...params}
						key={'t'}
						fullWidth
						error={Boolean(touched[exactFieldName] && errors[exactFieldName])}
						label={optionLabel}
						variant="outlined"
						name={exactFieldName}
						required={required}
						helperText={touched[exactFieldName] && errors[exactFieldName]}
						onClick={() => setFieldTouched(exactFieldName, true, true)}
						inputRef={(input) => {
							return {
								inputRef: input
							}
						}}
					/>
				)}
			/>
		</>
	)
}

AutocompleteSingleselect.defaultProps = {
	required: false,
	disabled: false,
	dependentFieldNames: []
}

AutocompleteSingleselect.propTypes = {
	options: PropTypes.array.isRequired,
	id: PropTypes.string.isRequired,
	exactFieldName: PropTypes.string.isRequired,
	optionLabel: PropTypes.string.isRequired
}
export default AutocompleteSingleselect
