import { BudgetLinesContractVariation, ContractVariationPercentageLine, SalesLinesContractVariation } from '@types'

export class ContractVariation {
	contractVariationId: number
	titlePrefix: string
	title: string
	projectId: number
	projectName: string
	projectNumber: string
	personInChargeFirstName: string
	personInChargeLastName: string
	projectLeader: string
	version: number
	versionDate: Date
	status: string
	statusId: number
	internalNote: any
	approvalNote: any
	approvedDateTime: Date
	approvedByName: string
	salesLines: SalesLinesContractVariation[]
	budgetLines: BudgetLinesContractVariation[]
	budgetLinePercentages: ContractVariationPercentageLine[]
	salesLinePercentages: ContractVariationPercentageLine[]
	intendedResult?: number
	externalNote: string
	projectPrincipal: string
	projectPrincipalNo: string

	constructor(data: ContractVariation) {
		Object.assign(this, data)

		if (data.versionDate) {
			this.versionDate = new Date(data.versionDate)
		}

		if (data.approvedDateTime) {
			this.approvedDateTime = new Date(data.approvedDateTime)
		}

		if (data.personInChargeFirstName && data.personInChargeLastName) {
			this.projectLeader = `${this.personInChargeFirstName} ${this.personInChargeLastName}`
		}
	}
}
