import http from '../http-common'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Role } from '@types'

const responseBody = (response: AxiosResponse) => response.data

const requests = {
	get: (url: string, config?: AxiosRequestConfig) => http.get(url, config).then(responseBody),
	post: (url: string, config: Role) =>
		http
			.post(url, config)
			.then(responseBody)
			.catch((error) => {
				return Promise.reject(error)
			}),
	delete: (url: string) => http.delete(url).then(responseBody)
}

export const mandaysRegisterService = {
	getMandaysRegisters: (params: any): Promise<any> => requests.get('mandaysregister', { params: params }),
	createRegister: (mandaysRegister: any): Promise<void> =>
		requests.post('mandaysregister', mandaysRegister).catch((error) => {
			return Promise.reject(error)
		})
}
