import http from '../http-common'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { AutocompleteShape, Role } from '@types'

const responseBody = (response: AxiosResponse) => response.data

const requests = {
	get: (url: string, config?: AxiosRequestConfig) => http.get(url, config).then(responseBody),
	post: (url: string, config: Role) =>
		http
			.post(url, config)
			.then(responseBody)
			.catch((error) => {
				return Promise.reject(error)
			}),
	delete: (url: string) => http.delete(url).then(responseBody)
}

export const roleGroupService = {
	getRoleGroups: (params: any): Promise<any> => requests.get('/roleGroups', { params: params }),
	getRoleGroup: (params: any): Promise<any> => requests.get(`roleGroups/role-group-details/${params}`),
	getUsersInRoleGroup: (params: any): Promise<any> => requests.get('roleGroups/users-in-role-group', { params: params }),
	getRolesInRoleGroup: (params: any): Promise<any> => requests.get('roleGroups/roles-in-role-group', { params: params }),
	deleteRoleGroup: (params: string): Promise<any> => requests.delete(`roleGroups/delete/${params}`),
	createRoleGroup: (role: Role): Promise<void> =>
		requests.post('roleGroups/create', role).catch((error) => {
			return Promise.reject(error)
		}),
	editRoleGroup: (role: any): Promise<any> => requests.post('roleGroups/edit', role),
	getRoleGroupsAutocomplete: (): Promise<AutocompleteShape[]> => requests.get('roleGroups/get-roleGroupsDropdownList'),
	addOrRemoveRolesFromRoleGroup: (model: any): Promise<void> =>
		requests.post('roleGroups/add-remove-roles-from-role-group', model).catch((error) => {
			return Promise.reject(error)
		})
}
