import { CampaignCreateForm } from '@types'

export class CampaignCreate {
	customerId: string
	customerName: string
	businessRelationContactNo: string
	description: string
	streetAndHouseNumber: string
	city: string
	contactPerson: string
	phoneNumber: string
	startingDate: Date
	endingDate: Date
	postalCode: string
	salesPurchasePersonCode: string | number

	constructor(data: CampaignCreateForm) {
		Object.assign(this, data)

		if (data) {
			this.streetAndHouseNumber = data.street
			this.startingDate = data.startDate
			this.endingDate = data.endDate
			this.salesPurchasePersonCode = data.salesPerson.value
			if (data.customer) {
				this.customerId = data.customer.value
				this.customerName = data.customer.name
				this.businessRelationContactNo = data.customer.businessRelationContactNo
			}
		}
	}
}
