import {
	ContractVariation,
	ContractVariationStatus,
	CVAttachmentType,
	GetBudgetDimensionsModel,
	SaleLineUnit,
	UpdateContractVariationModel,
	UpdateContractVariationStatusModel,
	UpdateCVInternalNotesModel
} from '@types'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import http from '../http-common'

const responseBody = (response: AxiosResponse) => response.data

const request = {
	getWithoutBody: (url: string, config?: AxiosRequestConfig) => http.get(url, config),
	get: (url: string, config?: AxiosRequestConfig) => http.get(url, config).then(responseBody),
	post: (url: string, data: any, config?: AxiosRequestConfig) =>
		http
			.post(url, data, config)
			.then(responseBody)
			.catch((error) => {
				return Promise.reject(error)
			}),
	put: (url: string, data: any, config?: AxiosRequestConfig) =>
		http
			.put(url, data, config)
			.then(responseBody)
			.catch((error) => {
				return Promise.reject(error)
			}),
	delete: (url: string) => http.delete(url).then(responseBody)
}

export const contractVariationService = {
	getContractVariationDetails: (contractVariationId: string): Promise<ContractVariation> =>
		request.get(`/contractvariations/${contractVariationId}`),
	getCVPDFDetails: (contractVariationId: string): Promise<any> =>
		request.get(`/contractvariations/document/${contractVariationId}`, { responseType: 'blob' }),
	getCVAttachments: (contractVariationId: string, params: any): Promise<any> =>
		request.get(`/contractvariations/attachments/${contractVariationId}`, { params: params }),
	getCVStatuses: (): Promise<ContractVariationStatus[]> => request.get('/contractvariations/statuses'),
	uploadCVAttachment: (data: any): Promise<void> =>
		request.post('/contractvariations/attachment', data, { headers: { 'Content-Type': 'multipart/form-data' } }),
	getCVDimensions: (): Promise<GetBudgetDimensionsModel> => request.get('/contractvariations/dimensionvalues'),
	getCVSaleLineUnits: (): Promise<SaleLineUnit[]> => request.get('/contractvariations/units'),
	getCVAttachmentTypes: (): Promise<CVAttachmentType[]> => request.get('/contractvariations/attachmenttypes'),
	updateContractVariationDetails: (contractVariationData: UpdateContractVariationModel): Promise<void> =>
		request.put('/contractvariations', contractVariationData),
	updateCVStatus: (updateStatusData: UpdateContractVariationStatusModel): Promise<void> =>
		request.put('/contractvariations/updatestatus', updateStatusData),
	getCVInternalNotes: (contractVariationId: string): Promise<any> =>
		request
			.getWithoutBody(`/contractvariations/internalcomments/${contractVariationId}`)
			.then((response) => {
				if (response.status == 204) {
					return Promise.resolve(null)
				}

				return Promise.resolve(response.data)
			})
			.catch((error) => {
				console.log('GET CV internal response body', error)
				return Promise.reject(error)
			}),
	updateCVInternalNotes: (updateCVInternalNoteModel: UpdateCVInternalNotesModel): Promise<void> =>
		request.put('/contractvariations/internalcomments', updateCVInternalNoteModel),
	deleteCVAttachment: (cvAttachmentId: string): Promise<void> =>
		request.delete(`/contractvariations/attachment//${cvAttachmentId}`)
}
