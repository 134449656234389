import http from '../http-common'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

const responseBody = (response: AxiosResponse) => response.data

const requests = {
	get: (url: string, config: AxiosRequestConfig) => http.get(url, config).then(responseBody)
}

export const stateService = {
	getStatesAutocomplete: (params: any): Promise<any> => requests.get('/states/get-statesdropdownlist', { params })
}
