/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useEffect, useReducer } from 'react'
import type { FC, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { State, User, AuthType } from '@types'
import { authApi } from '@data/authApi'
import { useIsAuthenticated } from '@azure/msal-react'

interface AuthContextValue extends State {
	isAuthenticated: () => boolean
	login: (email: string, password: string) => Promise<void>
	loginMicrosoft: (userMicrosoft: any) => Promise<void>
	logout: () => Promise<void>
	register: (user: User) => Promise<void>
	hasRole: (role: string) => boolean
}

interface AuthProviderProps {
	children: ReactNode
}

type InitializeAction = {
	type: 'INITIALIZE'
	payload: {
		isAuthenticatedLocal: boolean
		user: User | null
	}
}

type LoginAction = {
	type: 'LOGIN'
	payload: {
		user: User
	}
}

type LoginMicrosoftAction = {
	type: 'LOGIN_MICROSOFT'
	payload: {
		user: User
	}
}

type LogoutAction = {
	type: 'LOGOUT'
}

type RegisterAction = {
	type: 'REGISTER'
	payload: {
		user: User
	}
}

type Action = InitializeAction | LoginAction | LoginMicrosoftAction | LogoutAction | RegisterAction

const initialState: State = {
	isAuthenticatedLocal: false,
	isInitialized: false,
	type: AuthType.Local,
	user: null
}

const handlers: Record<string, (state: State, action: Action) => State> = {
	INITIALIZE: (state: State, action: InitializeAction): State => {
		const { isAuthenticatedLocal, user } = action.payload

		return {
			...state,
			isAuthenticatedLocal,
			isInitialized: true,
			user
		}
	},
	LOGIN: (state: State, action: LoginAction): State => {
		const { user } = action.payload

		return {
			...state,
			isAuthenticatedLocal: true,
			user
		}
	},
	LOGIN_MICROSOFT: (state: State, action: LoginMicrosoftAction): State => {
		const { user } = action.payload
		return {
			...state,
			user
		}
	},

	LOGOUT: (state: State): State => ({
		...state,
		isAuthenticatedLocal: false,
		user: null
	}),
	REGISTER: (state: State, action: RegisterAction): State => {
		const { user } = action.payload

		return {
			...state,
			isAuthenticatedLocal: true,
			user
		}
	}
}

const reducer = (state: State, action: Action): State =>
	handlers[action.type] ? handlers[action.type](state, action) : state

const AuthContext = createContext<AuthContextValue>({
	...initialState,
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	isAuthenticated: () => { },
	login: () => Promise.resolve(),
	loginMicrosoft: () => Promise.resolve(),
	logout: () => Promise.resolve(),
	register: () => Promise.resolve(),
	//hasRole: () => { },
})

export const AuthProvider: FC<AuthProviderProps> = (props) => {
	const { children } = props
	const [state, dispatch] = useReducer(reducer, initialState)

	useEffect(() => {
		const initialize = async (): Promise<void> => {
			try {
				const userLocal: User = new User(JSON.parse(window.localStorage.getItem('user')))
				if (userLocal.jwtToken != undefined) {
					const user = await authApi.me(userLocal)

					dispatch({
						type: 'INITIALIZE',
						payload: {
							isAuthenticatedLocal: true,
							user
						}
					})
				} else {
					dispatch({
						type: 'INITIALIZE',
						payload: {
							isAuthenticatedLocal: false,
							user: null
						}
					})
				}
			} catch (err) {
				console.error(err)
				dispatch({
					type: 'INITIALIZE',
					payload: {
						isAuthenticatedLocal: false,
						user: null
					}
				})
			}
		}

		initialize()
	}, [])

	const isAuthenticated = (): boolean => {
		const isAuthenticated = useIsAuthenticated()
		const userLocal: User = new User(JSON.parse(window.localStorage.getItem('user')))
		if (!userLocal.jwtToken && isAuthenticated == false) {
			return false
		}
		switch (state.type) {
			case AuthType.Local:
				return state.isAuthenticatedLocal
			case AuthType.Microsoft:
				return isAuthenticated
			default:
				return false
		}
	}

	const hasRole = (role: string) : boolean => {
		const userLocal: User = new User(JSON.parse(window.localStorage.getItem('user')))

		if (!role) {
			return true
		}
		if (userLocal.roles) {
			const upperCasedRoles = userLocal.roles.map(s => s.toUpperCase())
			return userLocal.roles && upperCasedRoles.indexOf(role.toUpperCase()) > -1
		}
	}

	const login = async (email: string, password: string): Promise<void> => {
		const userLogged = await authApi.login({ email, password })
		const user = await authApi.me(userLogged)
		localStorage.setItem('user', JSON.stringify(user))
		dispatch({
			type: 'LOGIN',
			payload: {
				user: user
			}
		})
	}

	const loginMicrosoft = async (userMicrosoft: any): Promise<void> => {
		state.type = AuthType.Microsoft
		localStorage.setItem('user', JSON.stringify(userMicrosoft))
		dispatch({
			type: 'LOGIN_MICROSOFT',
			payload: {
				user: userMicrosoft
			}
		})
	}

	const logout = async (): Promise<void> => {
		localStorage.removeItem('user')
		dispatch({ type: 'LOGOUT' })
	}

	const register = async (user: User): Promise<void> => {
		const accessToken = await authApi.register({ user })
		const userToSet = await authApi.me(accessToken)

		localStorage.setItem('user', userToSet.toString())

		dispatch({
			type: 'REGISTER',
			payload: {
				user
			}
		})
	}

	return (
		<AuthContext.Provider
			value={{
				...state,
				isAuthenticated,
				login,
				loginMicrosoft,
				logout,
				register,
				hasRole
			}}>
			{children}
		</AuthContext.Provider>
	)
}

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired
}

export default AuthContext
