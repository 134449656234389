import { wait } from '@utils'
import { User } from '@types'
import http from '../http-common'

class AuthApi {
	async login({ email, password }: { email: string; password: string }): Promise<User> {
		await wait(500)

		return new Promise((resolve, reject) => {
			try {
				// Find the user
				http
					.post('/internal-login', { email: email, password: password })
					.then((response) => {
						const user = response.data.user
						user.roles = response.data.roles
						//const accessToken = sign({ userId: user.id }, JWT_SECRET, { expiresIn: JWT_EXPIRES_IN })
						resolve(user)
					})
					.catch(() => {
						reject(new Error('Email or Password is invalid'))
						return
					})
			} catch (err) {
				console.error('[Auth Api]: ', err)
				reject(new Error('Internal server error'))
			}
		})
	}

	async register({ user }: { user: User }): Promise<User> {
		await wait(1000)

		return new Promise((resolve, reject) => {
			try {
				const userModel = user
				http
					.post('/register', userModel)
					.then((response) => {
						const user = response.data
						resolve(user)
					})
					.catch(() => {
						reject(new Error('Error register new user'))
						return
					})
			} catch (err) {
				console.error('[Auth Api]: ', err)
				reject(new Error('Internal server error'))
			}
		})
	}

	me(user: User): Promise<User> {
		return new Promise((resolve, reject) => {
			try {
				//Decode access token
				// const { userId } = decode(accessToken) as any

				// // Find the user
				// const user = users.find((_user) => _user.id === userId)

				// if (!user) {
				// 	reject(new Error('Invalid authorization token'))
				// 	return
				// }
				resolve({
					id: user.id,
					companyId: user.companyID_FK,
					avatar: user.avatar,
					email: user.email,
					name: user.name,
					jwtToken: user.jwtToken,
					firstName: user.firstName,
					lastName: user.lastName,
					city: user.city,
					country: user.country,
					state: user.state,
					phoneNumber: user.phoneNumber,
					password: user.password,
					roles: user.roles,
					roleGroups: user.roleGroups,
					salesPurchasePersonCode: user.salesPurchasePersonCode,
					planGroup: user.planGroup
				})
			} catch (err) {
				console.error('[Auth Api]: ', err)
				reject(new Error('Internal server error'))
			}
		})
	}

	isAuthenticated(): boolean {
		http
			.get<boolean>('/is-authenticated', { withCredentials: true })
			.then((response) => {
				return response.data
			})
			.catch(() => {
				return false
			})
		return false
	}
}

export const authApi = new AuthApi()
