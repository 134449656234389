import { useRef, useState, FC } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
	Avatar,
	Box,
	Button,
	ButtonBase,
	Divider,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Popover,
	Typography
} from '@mui/material'
import { useAuth } from '@hooks'
import { CogIcon } from '@icons'
import { useMsal } from '@azure/msal-react'
import { AuthType } from '@types'
import { useTranslation } from 'react-i18next'

const AccountPopover: FC = () => {
	const { t } = useTranslation()
	const anchorRef = useRef<HTMLButtonElement | null>(null)
	const { user, logout, type } = useAuth()
	const { instance } = useMsal()
	const navigate = useNavigate()
	const [open, setOpen] = useState<boolean>(false)

	const handleOpen = (): void => {
		setOpen(true)
	}

	const handleClose = (): void => {
		setOpen(false)
	}

	const handleLogout = async (): Promise<void> => {
		try {
			switch (type) {
				case AuthType.Local:
					await logout()
					break
				case AuthType.Microsoft:
					localStorage.removeItem('user')
					await instance.logoutPopup({
						postLogoutRedirectUri: '/',
						mainWindowRedirectUri: '/'
					})
					break
			}
			navigate('/')
		} catch (err) {
			console.error(err)
			toast.error('Unable to logout.')
		}
	}

	return (
		<>
			<Box
				component={ButtonBase}
				onClick={handleOpen}
				ref={anchorRef}
				sx={{
					alignItems: 'center',
					display: 'flex'
				}}>
				<Avatar
					src={user?.avatar}
					sx={{
						height: 32,
						width: 32
					}}
				/>
			</Box>
			<Popover
				anchorEl={anchorRef.current}
				anchorOrigin={{
					horizontal: 'center',
					vertical: 'bottom'
				}}
				keepMounted
				onClose={handleClose}
				open={open}
				PaperProps={{
					sx: { width: 240 }
				}}>
				<Box sx={{ p: 2 }}>
					<Typography color="textPrimary" variant="subtitle2">
						{user.name}
					</Typography>
				</Box>
				<Divider />
				<Box sx={{ mt: 2 }}>
					<MenuItem component={RouterLink} to="/account">
						<ListItemIcon>
							<CogIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText
							primary={
								<Typography color="textPrimary" variant="subtitle2">
									{t('Settings')}
								</Typography>
							}
						/>
					</MenuItem>
				</Box>
				<Box sx={{ p: 2 }}>
					<Button color="primary" fullWidth onClick={handleLogout} variant="outlined">
						{t('Logout')}
					</Button>
				</Box>
			</Popover>
		</>
	)
}

export default AccountPopover
