import type { FC } from 'react'
import PropTypes from 'prop-types'
import { AppBar, Box, IconButton, Toolbar } from '@mui/material'
import { experimentalStyled } from '@mui/material/styles'
import type { AppBarProps } from '@mui/material'
import { MenuIcon } from '@icons'
import AccountPopover from './AccountPopover'
import LanguagePopover from './LanguagePopover'
import Logo from '../Logo'
// import NotificationsPopover from './NotificationsPopover'

interface NavbarProps extends AppBarProps {
	onSidebarMobileOpen?: () => void
}

const NavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
	...(theme.palette.mode === 'light' && {
		backgroundColor: theme.palette.primary.main,
		boxShadow: 'none',
		color: theme.palette.primary.contrastText
	}),
	...(theme.palette.mode === 'dark' && {
		backgroundColor: theme.palette.background.paper,
		borderBottom: `1px solid ${theme.palette.divider}`,
		boxShadow: 'none'
	}),
	zIndex: theme.zIndex.drawer + 100
}))

const Navbar: FC<NavbarProps> = (props) => {
	const { onSidebarMobileOpen, ...other } = props

	return (
		<NavbarRoot {...other}>
			<Toolbar sx={{ minHeight: 64 }}>
				<IconButton
					color="inherit"
					onClick={onSidebarMobileOpen}
					sx={{
						display: {
							lg: 'none'
						}
					}}
					size="large">
					<MenuIcon fontSize="small" />
				</IconButton>
				<Logo size="50px" />
				<Box
					sx={{
						flexGrow: 1,
						ml: 2
					}}
				/>
				<LanguagePopover />
				{/* <Box sx={{ ml: 1 }}>
					<NotificationsPopover />
				</Box> */}
				<Box sx={{ ml: 2 }}>
					<AccountPopover />
				</Box>
			</Toolbar>
		</NavbarRoot>
	)
}

Navbar.propTypes = {
	onSidebarMobileOpen: PropTypes.func
}

export default Navbar
