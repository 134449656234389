import { useEffect, FC } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Box, Button, FormHelperText, TextField } from '@mui/material'
import { useAuth, useMounted } from '@hooks'
import { useMsal } from '@azure/msal-react'
import { useTranslation } from 'react-i18next'

const Login: FC = (props) => {
	const { t } = useTranslation()
	const mounted = useMounted()
	const { user, login, loginMicrosoft } = useAuth() as any
	const { accounts } = useMsal()

	useEffect(() => {
		if (accounts.length > 0 && accounts[0] && !user) {
			const user = {
				id: accounts[0].localAccountId,
				avatar: `https://eu.ui-avatars.com/api/?name=${accounts[0].name.split(' ').join('+')}`,
				email: accounts[0].username,
				name: accounts[0].name
			}
			loginMicrosoft(user)
		}
	}, [accounts])

	return (
		<Formik
			initialValues={{
				email: '',
				password: '',
				submit: null
			}}
			validationSchema={Yup.object().shape({
				email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
				password: Yup.string().max(255).required('Password is required')
			})}
			onSubmit={async (values, { setErrors, setStatus, setSubmitting }): Promise<void> => {
				try {
					await login(values.email, values.password)
					if (mounted.current) {
						setStatus({ success: true })
						setSubmitting(false)
					}
				} catch (err) {
					console.error(err)
					setStatus({ success: false })
					setErrors({ submit: err.message })
					setSubmitting(false)
				}
			}}>
			{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }): JSX.Element => (
				<form noValidate onSubmit={handleSubmit} {...props}>
					<TextField
						error={Boolean(touched.email && errors.email)}
						fullWidth
						helperText={touched.email && errors.email}
						label={t('Email Address')}
						margin="normal"
						name="email"
						onBlur={handleBlur}
						onChange={handleChange}
						type="email"
						value={values.email}
						variant="outlined"
					/>
					<TextField
						error={Boolean(touched.password && errors.password)}
						fullWidth
						helperText={touched.password && errors.password}
						label={t('Password')}
						margin="normal"
						name="password"
						onBlur={handleBlur}
						onChange={handleChange}
						type="password"
						value={values.password}
						variant="outlined"
					/>
					{errors.submit && (
						<Box sx={{ mt: 3 }}>
							<FormHelperText error>{errors.submit}</FormHelperText>
						</Box>
					)}
					<Box sx={{ mt: 2 }}>
						<Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
							{t('Log In')}
						</Button>
					</Box>
					{/* <Box sx={{ mt: 2 }}>
						<Button
							color="primary"
							fullWidth
							size="large"
							variant="outlined"
							onClick={handleLogin}
							startIcon={<MicrosoftIcon />}>
							Log In with Microsoft account
						</Button>
					</Box> */}
				</form>
			)}
		</Formik>
	)
}

export default Login
