import { WorkOrderCreateForm } from '@types'

export class WorkOrderCreate {
	orderType: number
	campaign: string
	campaignTo: string
	customerFrom: string
	customerTo: string
	customAddressName: string
	toCustomAddressName: string
	street: string
	postalCode: string
	city: string
	contact: string
	streetTo: string
	postalCodeTo: string
	cityTo: string
	contactTo: string
	invoiceAddress: string
	phoneContactPersonTo: string
	phoneContactPerson: string
	invoiceCompanyNameFrom: string
	invoiceCompanyNameTo: string
	invoiceStreet: string
	invoicePostalCode: string
	invoiceCity: string
	invoiceAddressTo: string
	invoiceStreetTo: string
	invoicePostalCodeTo: string
	invoiceEmailTo: string
	invoiceCityTo: string
	transportMethod: string
	invoiceEmail: string
	loadingTime: string
	licensePlate: string
	purchaser: string
	unloadAddress: string
	loadName: string
	unloadingName: string
	seller: string
	loadingAddress: string
	relationshipType: string
	loadingDate: Date
	customAddress: boolean
	toCustomAddress: boolean
	lines: any[]
	submit: any
	cityId: number
	cityToId: number

	constructor(data: WorkOrderCreateForm) {
		Object.assign(this, data)
		if (data) {
			if (data.loadingTime) {
				this.loadingTime = data.loadingTime.toLocaleTimeString('nl-NL')
			}
			if (data.orderType) {
				this.orderType = Number(data.orderType.value)
			}

			if (data.customerFrom) {
				if (typeof data.customerFrom === 'string') {
					this.customerFrom = data.customerFrom
				} else {
					this.customerFrom = data.customerFrom.value
				}
			}

			if (data.relationshipType) {
				this.relationshipType = data.relationshipType.value
			}

			if (data.loadingAddress) {
				this.loadingAddress = data.loadingAddress.value
			}

			if (data.customerTo) {
				if (typeof data.customerTo === 'string') {
					this.customerTo = data.customerTo
				} else {
					this.customerTo = data.customerTo.value
				}
			}

			if (data.campaign) {
				this.campaign = data.campaign.value
			}

			if (data.campaignTo) {
				this.campaignTo = data.campaignTo.value
			}

			if (data.unloadAddress) {
				this.unloadAddress = data.unloadAddress.value
			}

			if (data.transportMethod) {
				this.transportMethod = data.transportMethod.value
			}

			if (data.cityId) {
				this.cityId = data.cityId.value
			} else {
				this.cityId = 0
			}

			if (data.cityToId) {
				this.cityToId = data.cityToId.value
			} else {
				this.cityToId = 0
			}
		}
	}
}
