import type { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'

interface LogoProps {
	color?: string
	size?: string
}

const Logo: FC<LogoProps> = ({ size }) => {
	const imgSrc = '/Logo.png'

	return (
		<RouterLink style={{ textDecoration: 'none' }} to="/">
			<img src={imgSrc} style={{ height: size }} alt="JdB" />
		</RouterLink>
	)
}

export default Logo
