import { useContext } from 'react'
import AuthContext from '@contexts/AuthContext'
import type { State, User } from '@types'

interface AuthContextValue extends State {
	isAuthenticated: () => boolean
	login: (email: string, password: string) => Promise<void>
	loginMicrosoft: (userMicrosoft: any) => Promise<void>
	logout: () => Promise<void>
	register: (user: User) => Promise<void>
	hasRole: (role: string) => boolean
}

const useAuth = (): AuthContextValue => useContext(AuthContext)

export default useAuth
