import { ContractVariationPercentageLine } from '@types'

export class SalesLinesContractVariation {
	salesLineId?: number
	position: number
	description: string
	quantity: number
	unit: string
	unitPrice: number
	amount: number

	constructor(data: SalesLinesContractVariation | ContractVariationPercentageLine) {
		Object.assign(this, data)
	}
}
