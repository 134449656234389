import MandaysRegisterTable from '@components/mandaysRegister/MandaysRegisterTable'
import { useSettings } from '@hooks'
import {
	ChevronRightIcon
	// , PlusIcon
} from '@icons'
import {
	Box,
	Breadcrumbs,
	// , Button
	Container,
	Grid,
	Typography
} from '@mui/material'
import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import MandaysRegisterCreate from './MandaysRegisterCreate'

const MandaysRegisterOverview: FC = () => {
	const { t } = useTranslation()
	const { settings } = useSettings()
	// const navigate = useNavigate()

	return (
		<>
			<Helmet>
				<title>{t('mandaysRegister')}</title>
			</Helmet>
			<Box
				sx={{
					backgroundColor: 'background.default',
					minHeight: '100%',
					py: 8
				}}>
				<Container maxWidth={settings.compact ? 'xl' : false}>
					<Grid container justifyContent="space-between" spacing={3}>
						<Grid item>
							<Typography color="textPrimary" variant="h5">
								{t('mandaysRegister')}
							</Typography>
							<Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon fontSize="small" />} sx={{ mt: 1 }}>
								<Typography color="textSecondary" variant="subtitle2">
									{t('mandaysRegister')}
								</Typography>
							</Breadcrumbs>
						</Grid>
						<Grid item>
							<Box sx={{ m: -1 }}>
								<MandaysRegisterCreate />
							</Box>
						</Grid>
					</Grid>
					<Box sx={{ mt: 3 }}></Box>
					<MandaysRegisterTable></MandaysRegisterTable>
				</Container>
			</Box>
		</>
	)
}

export default MandaysRegisterOverview
