export class User {
	id: string
	avatar: string
	email: string
	name: string
	jwtToken: string;
	[key: string]: any
	firstName: string
	lastName: string
	city: string
	country: string
	state: string
	phoneNumber: string
	password: string
	roles: string[]
	roleGroups: any[]
	salesPurchasePersonCode: any
	planGroup: any

	constructor(data: User) {
		if (data) {
			if (data?.name == undefined) data.name = data.firstName + ' ' + data.lastName

			if (data.salesPurchasePersonCode) {
				this.salesPurchasePersonCode = data.salesPurchasePersonCode.value
			}
			if (data.planGroup) {
				this.planGroup = data.planGroup.value
			}
		}

		Object.assign(this, data)
	}
}
