import http from '../http-common'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Role } from '@types'

const responseBody = (response: AxiosResponse) => response.data

const requests = {
	get: (url: string, config?: AxiosRequestConfig) => http.get(url, config).then(responseBody),
	post: (url: string, config: Role) =>
		http
			.post(url, config)
			.then(responseBody)
			.catch((error) => {
				return Promise.reject(error)
			}),
	patch: (url: string, config: Role) =>
		http
			.patch(url, config)
			.then(responseBody)
			.catch((error) => {
				return Promise.reject(error)
			}),
	delete: (url: string) => http.delete(url).then(responseBody)
}

export const transportService = {
	getTransports: (params: any): Promise<any> => requests.get('/transports', { params: params }),
	approveTransport: (transport: any): Promise<any> => requests.patch('/transports/approve', transport)
}
