import { FormHelperText, TextField } from '@mui/material'
import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { FC } from 'react'

interface RVTextFieldProps {
	exactFieldName: string
	label: string
	value?: unknown
	required?: boolean
	type?: string
	disabled?: boolean
	InputProps?: any
	onChange?(e): void
}

const RVTextField: FC<RVTextFieldProps> = (props) => {
	const { required, type, value, exactFieldName, disabled, label, InputProps, onChange } = props
	const { errors, touched, handleBlur, setFieldTouched, handleChange } = useFormikContext()

	return (
		<>
			<TextField
				fullWidth
				error={Boolean(touched[exactFieldName] && errors[exactFieldName])}
				label={label}
				variant="outlined"
				name={exactFieldName}
				type={type}
				size="small"
				required={required}
				onChange={(e) => {
					if (typeof onChange === 'function') {
						onChange(e)
					}
					handleChange(e)
				}}
				onClick={() => setFieldTouched(exactFieldName, true, true)}
				onBlur={handleBlur}
				value={value}
				disabled={disabled}
				InputProps={InputProps}
			/>
			{errors[exactFieldName] && touched[exactFieldName] && (
				<FormHelperText error>{errors[exactFieldName]}</FormHelperText>
			)}
		</>
	)
}

RVTextField.defaultProps = {
	type: 'text',
	required: false,
	disabled: false
}

RVTextField.propTypes = {
	exactFieldName: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired
}

export default RVTextField
