import makeStyles from '@mui/styles/makeStyles'
import { FC, MouseEvent } from 'react'
import QuickSearchToolbar from './QuickSearchToolbar'
import PropTypes from 'prop-types'
import { DataGrid, GridColDef, GridRowParams, GridSortModel, nlNL, enUS } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
	row: {
		cursor: 'pointer'
	},
	cell: {},
	columnHeader: {
		borderWidth: 0
	},
	root: {
		borderWidth: 0,
		'&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
			outline: 'none'
		}
	}
})

interface RVDataGridPaginationFilter {
	pageSize: number
	page: number
	keyword: string
}

interface RVDataGridProps {
	rows: any[]
	columns: GridColDef[]
	rowCount: number
	paginationFilter: RVDataGridPaginationFilter
	gridLabel: string
	checkboxSelextion: boolean
	onPageSizeChangeHandler: (param: any) => void
	onSortModelChange: (event: GridSortModel) => void
	onRowClick?: (params: GridRowParams, _event: MouseEvent) => void
	onPageChange: (event: any) => void
	onSearchToolbarChanged: (text: string) => void
	disableDensitySelector?: boolean
	disableColumnSelector?: boolean
	disableColumnFilter?: boolean
	disableSearchFilter?: boolean
	disableFooterPagination?: boolean
	defaultSortModel?: any[]
	clientSidePagination?: boolean
}

const RVDataGrid: FC<RVDataGridProps> = (props) => {
	const {
		rows,
		rowCount,
		columns,
		paginationFilter,
		gridLabel,
		checkboxSelextion,
		disableColumnFilter,
		disableSearchFilter,
		disableFooterPagination,
		defaultSortModel,
		clientSidePagination,
		onSearchToolbarChanged,
		onPageSizeChangeHandler,
		onSortModelChange,
		onRowClick,
		onPageChange,
		...other
	} = props
	const classes = useStyles()

	const { i18n } = useTranslation()

	return (
		<DataGrid
			localeText={
				i18n.language == 'nl'
					? nlNL.components.MuiDataGrid.defaultProps.localeText
					: enUS.components.MuiDataGrid.defaultProps.localeText
			}
			aria-label={gridLabel}
			density="compact"
			disableExtendRowFullWidth
			disableDensitySelector={false}
			disableColumnSelector={false}
			autoHeight
			rows={rows}
			columns={columns}
			pageSize={paginationFilter.pageSize}
			rowsPerPageOptions={[10, 25, 50, 100]}
			onPageSizeChange={onPageSizeChangeHandler}
			sortingMode={'server'}
			paginationMode={clientSidePagination ? 'client' : 'server'}
			onSortModelChange={onSortModelChange}
			page={paginationFilter.page - 1}
			hideFooterPagination={disableFooterPagination}
			onPageChange={onPageChange}
			checkboxSelection={checkboxSelextion}
			disableSelectionOnClick
			disableColumnFilter={disableColumnFilter}
			rowCount={rowCount}
			onRowClick={onRowClick}
			components={{
				Toolbar: QuickSearchToolbar
			}}
			componentsProps={{
				toolbar: {
					value: paginationFilter.keyword,
					onChange: (event) => onSearchToolbarChanged(event.target.value),
					clearSearch: () => onSearchToolbarChanged(''),
					disableSearchFilter: disableSearchFilter
				}
			}}
			classes={{
				root: classes.root,
				columnHeader: classes.columnHeader,
				row: classes.row,
				cell: classes.cell
			}}
			sortModel={defaultSortModel}
			{...other}
		/>
	)
}

RVDataGrid.propTypes = {
	rows: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	rowCount: PropTypes.number.isRequired,
	paginationFilter: PropTypes.any.isRequired,
	gridLabel: PropTypes.string.isRequired,
	onSearchToolbarChanged: PropTypes.func.isRequired,
	onPageSizeChangeHandler: PropTypes.func.isRequired,
	onSortModelChange: PropTypes.func.isRequired,
	onPageChange: PropTypes.func.isRequired
}

RVDataGrid.defaultProps = {
	disableColumnFilter: true
}

export default RVDataGrid
