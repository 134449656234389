import http from '../http-common'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { AutocompleteShape, Role } from '@types'

const responseBody = (response: AxiosResponse) => response.data

const requests = {
	get: (url: string, config?: AxiosRequestConfig) => http.get(url, config).then(responseBody),
	post: (url: string, config: Role) =>
		http
			.post(url, config)
			.then(responseBody)
			.catch((error) => {
				return Promise.reject(error)
			}),
	delete: (url: string) => http.delete(url).then(responseBody)
}

export const orderTypeService = {
	getOrderTypesAutocomplete: (lang?): Promise<AutocompleteShape[]> =>
		requests.get('orderTypes/get-dropDownList', { params: lang })
}
