import { useState, useEffect, FC, ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import { experimentalStyled } from '@mui/material/styles'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Scrollbar from '../Scrollbar'

interface LayoutProps {
	children?: ReactNode
}

const LayoutRoot = experimentalStyled('div')(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	display: 'flex',
	height: '100%',
	overflow: 'hidden',
	width: '100%'
}))

const LayoutWrapper = experimentalStyled('div')(({ theme }) => ({
	display: 'flex',
	flex: '1 1 auto',
	overflow: 'hidden',
	paddingTop: '34px',
	[theme.breakpoints.up('lg')]: {
		paddingLeft: '240px'
	}
}))

const LayoutContainer = experimentalStyled('div')({
	display: 'flex',
	flex: '1 1 auto',
	overflow: 'hidden'
})

const LayoutContent = experimentalStyled('div')({
	flex: '1 1 auto',
	height: '100%',
	overflow: 'auto',
	position: 'relative',
	WebkitOverflowScrolling: 'touch'
})

const Layout: FC<LayoutProps> = () => {
	const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false)

	useEffect(() => {
		/***/
	}, [isSidebarMobileOpen])

	return (
		<LayoutRoot>
			<Navbar onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)} />
			<Sidebar onMobileClose={(): void => setIsSidebarMobileOpen(false)} openMobile={isSidebarMobileOpen} />
			<LayoutWrapper>
				<LayoutContainer>
					<LayoutContent>
						<Scrollbar>
							<Outlet />
						</Scrollbar>
					</LayoutContent>
				</LayoutContainer>
			</LayoutWrapper>
		</LayoutRoot>
	)
}

export default Layout
