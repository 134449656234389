import { useState } from 'react'
import type { FC } from 'react'
import { Card, IconButton, Tooltip } from '@mui/material'
import React from 'react'
import RVDataGrid from '@components/layout/RVDataGrid'
import { GridColDef, GridSortModel } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { mandaysRegisterService } from '@services'
import { pink } from '@mui/material/colors'

interface MyPaginationFilter {
	pageSize: number
	keyword: string
	pageNumber: number
}

const MandaysRegisterTable: FC = (props) => {
	const { t, i18n } = useTranslation()
	const { ...other } = props
	const [myPaginationFilter, setMyPaginationFilter] = useState<MyPaginationFilter>({
		pageSize: 25,
		pageNumber: 1,
		keyword: ''
	})
	const [totalNumberOfRowsInDB, setTotalNumberOfRowsInDB] = useState<number>(0)
	const [sortByField, setSortByField] = useState<string>()
	const [rows, setRows] = useState<any[]>([])

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: t('sourceId'),
			type: 'number',
			headerAlign: 'right',
			align: 'right',
			filterable: true,
			hide: true
		},
		{
			field: 'projectNo',
			headerName: t('projectNo'),
			width: 160,
			type: 'string',
			filterable: true,
			flex: 3
		},
		{
			field: 'year',
			headerName: t('year'),
			width: 160,
			type: 'string',
			filterable: true,
			flex: 1
		},
		{
			field: 'week',
			headerName: t('week'),
			type: 'number',
			filterable: true,
			flex: 1
		},
		{
			field: 'user',
			headerName: t('requested By'),
			width: 200,
			type: 'string',
			filterable: true,
			flex: 4
		},
		{
			field: 'statusCode',
			headerName: t('status'),
			type: 'number',
			filterable: true,
			renderCell: (params) => {
				return (
					<div style={{ display: 'flex' }}>
						{params.value != 200 ? (
							<Tooltip title={params.row.errorMessage}>
								<IconButton>
									<CloseIcon sx={{ color: pink[500] }} />
								</IconButton>
							</Tooltip>
						) : (
							<Tooltip title={params.row.errorMessage}>
								<IconButton>
									<CheckIcon color="success" />
								</IconButton>
							</Tooltip>
						)}
					</div>
				)
			},
			flex: 2
		}
	]

	React.useEffect(() => {
		setRows([])
		retrieveMandaysRegisters()
	}, [myPaginationFilter, sortByField, i18n.language])

	const retrieveMandaysRegisters = () => {
		mandaysRegisterService
			.getMandaysRegisters({ ...myPaginationFilter, sortByField })
			.then((response) => {
				const data = response.data.map((row) => {
					const { orderType, orderTypeNl, ...rest } = row
					return {
						orderType: i18n.language == 'nl' ? orderTypeNl : orderType,
						...rest
					}
				})
				setRows(data)
				const totalRecordsKeyName = 'totalRecords'
				if (Object.keys(response).some((key) => key === totalRecordsKeyName)) {
					setTotalNumberOfRowsInDB(response[totalRecordsKeyName])
				}
			})
			.catch((e) => {
				console.error(e)
			})
	}

	const pageSizeChangeHandler = (param: any): void => {
		setMyPaginationFilter((prevState) => (prevState = { ...prevState, pageSize: param, pageNumber: 1 }))
	}

	const pageChangeHandler = (newPage: any): void => {
		if (!isNaN(newPage)) {
			setMyPaginationFilter((prevState) => (prevState = { ...prevState, pageNumber: newPage + 1 }))
		}
	}

	const sortModelChangeHandler = (event: GridSortModel): void => {
		if (event.length) {
			if (event[0].sort === 'asc') {
				setSortByField(event[0].field)
			} else {
				setSortByField(event[0].field + '_' + event[0].sort)
			}
		} else {
			setSortByField(null)
		}
	}

	const requestSearch = (searchValue: string) => {
		setMyPaginationFilter((prevState) => (prevState = { ...prevState, keyword: searchValue, pageNumber: 1 }))
	}

	return (
		<div>
			<Card {...other} style={{ height: '100%', width: '100%' }}>
				<RVDataGrid
					gridLabel={'Transporttable'}
					rows={rows}
					checkboxSelextion={false}
					columns={columns}
					rowCount={totalNumberOfRowsInDB}
					paginationFilter={{
						pageSize: myPaginationFilter.pageSize,
						page: myPaginationFilter.pageNumber,
						keyword: myPaginationFilter.keyword
					}}
					onPageChange={pageChangeHandler}
					onPageSizeChangeHandler={pageSizeChangeHandler}
					onSearchToolbarChanged={requestSearch}
					onSortModelChange={sortModelChangeHandler}
					disableColumnFilter={false}
				/>
			</Card>
		</div>
	)
}

export default MandaysRegisterTable
