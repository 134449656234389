import { User } from './user'

export interface State {
	isInitialized: boolean
	isAuthenticatedLocal: boolean
	type: AuthType
	user: User | any | null
}

export enum AuthType {
	Local = 'local',
	Microsoft = 'Microsoft'
}
