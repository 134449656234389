import { Configuration, PopupRequest } from '@azure/msal-browser'
import { authConfig } from '@configs'

export const msalConfig: Configuration = {
	auth: {
		clientId: authConfig.clientId,
		authority: authConfig.authority,
		redirectUri: authConfig.web_uri,
		postLogoutRedirectUri: authConfig.web_uri
	}
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
	scopes: ['api://14aea90f-69b7-4ea3-8882-bd7cb7eaf17f/Read']
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
	graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me'
}
