import { User } from '@types'
import axios from 'axios'

const axiosINstance = axios.create({
	baseURL: process.env.REACT_APP_API_URI,
	// baseURL: 'https://app-myjdbapi-jdb-test.azurewebsites.net',
	headers: { 'Content-type': 'application/json' }
})

axiosINstance.interceptors.request.use((config) => {
	const user: User = new User(JSON.parse(window.localStorage.getItem('user')))
	config.headers.Authorization = user.jwtToken ? `Bearer ${user.jwtToken}` : ''
	return config
})

axiosINstance.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (error.response) {
			if (error.response.status == 401) {
				localStorage.removeItem('user')
			}
		}

		return Promise.reject(error)
	}
)

export default axiosINstance
