import { IMandaysRegisterFormModel } from '@types'

export class MandaysRegisterFormModel {
	projectNo: string
	year: number
	weekNumbers: number[]

	constructor(data: IMandaysRegisterFormModel) {
		Object.assign(this, data)
	}
}
