import type { FC, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useAuth } from '@hooks'

interface GuestGuardProps {
	children: ReactNode
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
	const auth = useAuth()

	if (auth.isAuthenticated()) {
		return <Navigate to="/account" />
	}

	return <>{children}</>
}

GuestGuard.propTypes = {
	children: PropTypes.node
}

export default GuestGuard
