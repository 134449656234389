import http from '../http-common'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { AutocompleteShape, CustomerAutocomplete, Role } from '@types'

const responseBody = (response: AxiosResponse) => response.data

const requests = {
	get: (url: string, config?: AxiosRequestConfig) => http.get(url, config).then(responseBody),
	post: (url: string, config: Role) =>
		http
			.post(url, config)
			.then(responseBody)
			.catch((error) => {
				return Promise.reject(error)
			}),
	delete: (url: string) => http.delete(url).then(responseBody)
}

export const workOrderService = {
	getWorkOrders: (params: any): Promise<any> => requests.get('/workOrders', { params: params }),
	getCustomerAutocomplete: (): Promise<CustomerAutocomplete[]> => requests.get('customers/get-dropDownList'),
	createWorkOrder: (workOrder: any): Promise<void> =>
		requests.post('workOrders/create', workOrder).catch((error) => {
			return Promise.reject(error)
		}),
	getTransportMethodsAutocomplete: (): Promise<AutocompleteShape[]> => requests.get('transportMethods/get-dropDownList'),
	getCampaignsAutocomplete: (params): Promise<AutocompleteShape[]> =>
		requests.get('campaigns/get-dropDownList', { params: params }),
	getSuppliersAutocomplete: (): Promise<AutocompleteShape[]> => requests.get('vendors/get-dropDownList'),
	getLocationsAutocomplete: (): Promise<AutocompleteShape[]> => requests.get('locations/get-dropDownList'),
	getSalePurchasePersonsAutocomplete: (): Promise<AutocompleteShape[]> =>
		requests.get('salesAndPurchasePersons/get-dropDownList'),
	getItemsAutocomplete: (params): Promise<AutocompleteShape[]> =>
		requests.get('items/get-dropDownList', { params: params }),

	getShipToAddressAutocomplete: (params: any): Promise<AutocompleteShape[]> =>
		requests.get('shipToAddresses/get-dropDownList', { params }),
	getOrderAddressesAutocomplete: (params: any): Promise<AutocompleteShape[]> =>
		requests.get('orderAddresses/get-dropDownList', { params: params }),
	getItemUoM: (params): Promise<any[]> => requests.get('itemUnitOfMeasures/get-itemUnitOfMeasureList', { params }),
	getEuralCodes: (): Promise<any[]> => requests.get('eural/get-dropDownList'),
	getProcessingMethods: (): Promise<any[]> => requests.get('get-dropDownList'),
	getWorkOrderLines: (params): Promise<any[]> => requests.get('workOrders/get-workOrderLines', { params }),
	getPriceAgreements: (params): Promise<any[]> => requests.get('get-prijsafspraak-list', { params: params }),
	getSendersAutocomplete: (): Promise<AutocompleteShape[]> => requests.get('workOrderLines/get-sender-dropDownList'),
	getOrderLines: (params: any): Promise<any> => requests.get('workorders/get-workorderLines', { params }),
	getCities: (): Promise<AutocompleteShape[]> => requests.get('places/get-dropDownList'),
	getProjects: (params: any): Promise<any> => requests.get('projectjdbs/get-projects', { params }),
	getSoilTypesAutocomplete: (): Promise<AutocompleteShape[]> => requests.get('soilTypes/get-dropDownList'),
	getChemicalQualitiesAutocomplete: (): Promise<AutocompleteShape[]> =>
		requests.get('chemicalQualities/get-dropDownList'),
	getSalesPrice: (params): Promise<any[]> => requests.get('salesPrices/get-salesPrice', { params: params })
}
