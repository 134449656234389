import { useEffect, FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { Box, Card, CardContent, Container, Typography } from '@mui/material'
import { Login as LoginForm } from '@components/authentication'
import Logo from '@components/Logo'
import { gtm } from '@lib'
import { useTranslation } from 'react-i18next'

const Login: FC = () => {
	const { t } = useTranslation()

	useEffect(() => {
		gtm.push({ event: 'page_view' })
	}, [])

	return (
		<>
			<Helmet>
				<title>{t('Log In')}</title>
			</Helmet>
			<Box
				style={{ backgroundImage: 'url(/scania.jpeg)' }}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100vh'
				}}>
				<Container maxWidth="sm" sx={{ py: '80px' }}>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							mb: 8
						}}>
						<Logo />
					</Box>
					<Card>
						<CardContent
							sx={{
								display: 'flex',
								flexDirection: 'column',
								p: 4
							}}>
							<Box
								sx={{
									alignItems: 'center',
									display: 'flex',
									justifyContent: 'space-between',
									mb: 3
								}}>
								<div>
									<Typography color="textPrimary" gutterBottom variant="h4">
										{t('Log In')}
									</Typography>
								</div>
							</Box>
							<Box
								sx={{
									flexGrow: 1,
									mt: 3
								}}>
								<LoginForm />
							</Box>
						</CardContent>
					</Card>
				</Container>
			</Box>
		</>
	)
}

export default Login
