import { Project, ProjectPrincipal, ProjectSummary } from '@types'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import http from '../http-common'

const responseBody = (response: AxiosResponse) => response.data

const request = {
	get: (url: string, config?: AxiosRequestConfig) => http.get(url, config).then(responseBody),
	post: (url: string, config: AxiosRequestConfig) =>
		http
			.post(url, config)
			.then(responseBody)
			.catch((error) => {
				return Promise.reject(error)
			})
}

export const projectService = {
	getProjects: (onlyMyContractVariations: boolean, params: any): Promise<any> =>
		request.get(`/projects/${onlyMyContractVariations}`, { params: params }),
	getProjectSummary: (onlyMyProjects: boolean): Promise<ProjectSummary> =>
		request.get(`/projects/summary/${onlyMyProjects}`),
	getProjectDetails: (projectId: string, onlyMyContractVariations: boolean): Promise<Project> =>
		request.get(`/projects/${projectId}/${onlyMyContractVariations}`),
	getProjectContractVariations: (projectId: string, onlyShowMyContractVariations: boolean, params: any): Promise<any> =>
		request.get(`/projects/contractvariations/${projectId}/${onlyShowMyContractVariations}`, { params: params }),
	createNewContractVariation: (config: AxiosRequestConfig): Promise<number> =>
		request.post('contractvariations', config.data),
	getProjectPrincipals: (projectId: string): Promise<ProjectPrincipal[]> =>
		request.get(`/contractvariations/projectprincipals/${projectId}`)
}
