import { useEffect, useState, FC } from 'react'
import { Box, Button, Drawer, Fab, FormControlLabel, Switch, Tooltip, Typography } from '@mui/material'
import { useSettings } from '@hooks'
import { AdjustmentsIcon } from '@icons'
import { THEMES } from '@constants'
import { useTranslation } from 'react-i18next'

const getValues = (settings) => ({
	compact: settings.compact,
	direction: settings.direction,
	responsiveFontSizes: settings.responsiveFontSizes,
	roundedCorners: settings.roundedCorners,
	theme: settings.theme,
	settingsBtnVisible: settings.settingsBtnVisible
})

const SettingsDrawer: FC = () => {
	const { t } = useTranslation()
	const { settings, saveSettings } = useSettings()
	const [open, setOpen] = useState<boolean>(false)
	const [values, setValues] = useState(getValues(settings))
	const [darkBoxColor, setDarkBoxColor] = useState('#2d3748')
	const [lightBoxColor, setLightBoxColor] = useState('#7583eb')

	useEffect(() => {
		setValues(getValues(settings))
		setDarkBoxColor(settings.theme == THEMES.DARK ? '#7583eb' : '#dcdcdc')
		setLightBoxColor(settings.theme == THEMES.DARK ? '#2d3748' : '#7583eb')
	}, [settings])

	const handleOpen = (): void => {
		setOpen(true)
	}

	const handleClose = (): void => {
		setOpen(false)
	}

	const handleChange = (field, value): void => {
		setValues({
			...values,
			[field]: value
		})
	}

	const handleSave = (): void => {
		saveSettings(values)
		setOpen(false)
	}

	const changeTheme = (value): void => {
		if (settings.theme == THEMES.DARK) {
			handleChange('theme', value == 1 ? THEMES.LIGHT : THEMES.DARK)
			setDarkBoxColor(value == 1 ? '#2d3748' : '#7583eb')
			setLightBoxColor(value == 1 ? '#7583eb' : '#2d3748')
		} else {
			handleChange('theme', value == 1 ? THEMES.LIGHT : THEMES.DARK)
			setDarkBoxColor(value == 1 ? '#dcdcdc' : '#7583eb')
			setLightBoxColor(value == 1 ? '#7583eb' : '#dcdcdc')
		}
	}
	return (
		<>
			<Tooltip title={t('Settings')}>
				<Fab
					color="primary"
					onClick={handleOpen}
					size="medium"
					style={{ visibility: values.settingsBtnVisible ? 'visible' : 'hidden' }}
					sx={{
						bottom: 0,
						margin: (theme) => theme.spacing(4),
						position: 'fixed',
						right: 0,
						zIndex: (theme) => theme.zIndex.speedDial
					}}>
					<AdjustmentsIcon fontSize="small" />
				</Fab>
			</Tooltip>
			<Drawer
				anchor="right"
				onClose={handleClose}
				open={open}
				PaperProps={{
					sx: {
						p: 2,
						width: 320
					}
				}}>
				<Typography color="textPrimary" variant="h6" style={{ padding: '8px 8px 8px 0px', marginBottom: '10px' }}>
					{t('Theme settings')}
				</Typography>

				<Typography
					style={{
						fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif ',
						textTransform: 'uppercase',
						letterSpacing: '0.5px',
						color: 'rgb(160, 174, 192)',
						fontSize: '0.75rem',
						fontWeight: 600
					}}>
					{t('Color scheme')}
				</Typography>

				<Box style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
					<div>
						<Box
							alt="Under development"
							component="img"
							onClick={() => changeTheme(0)}
							src={`/darkTheme.svg`}
							sx={{
								maxWidth: '100%',
								cursor: 'pointer',
								width: 128,
								height: 112,
								borderWidth: '2px',
								borderRadius: '8px',
								margin: '8px',
								padding: '8px',
								flexGrow: 1,
								borderStyle: 'solid',
								borderColor: darkBoxColor
							}}
						/>
						<Typography
							color="textPrimary"
							variant="h6"
							style={{
								marginTop: -5,
								fontSize: '0.875rem',
								fontWeight: 500,
								lineHeight: 1.57,
								textAlign: 'center'
							}}>
							{t('Dark')}
						</Typography>
					</div>

					<div>
						<Box
							alt="Under development"
							component="img"
							onClick={() => changeTheme(1)}
							src={`/lightTheme.svg`}
							sx={{
								maxWidth: '100%',
								cursor: 'pointer',
								width: 128,
								height: 112,
								borderWidth: '2px',
								borderRadius: '8px',
								margin: '8px',
								padding: '8px',
								flexGrow: 1,
								borderStyle: 'solid',
								borderColor: lightBoxColor
							}}
						/>
						<Typography
							color="textPrimary"
							variant="h6"
							style={{
								marginTop: -5,
								fontSize: '0.875rem',
								fontWeight: 500,
								lineHeight: 1.57,
								textAlign: 'center'
							}}>
							{t('Light')}
						</Typography>
					</div>
				</Box>

				<Typography
					style={{
						fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
						textTransform: 'uppercase',
						letterSpacing: '0.5px',
						color: 'rgb(160, 174, 192)',
						fontSize: '0.75rem',
						fontWeight: 600
					}}>
					{t('Settings')}
				</Typography>

				<Box
					sx={{
						mt: 2,
						px: 1.5
					}}>
					<FormControlLabel
						control={
							<Switch
								checked={values.direction === 'rtl'}
								color="primary"
								edge="start"
								name="direction"
								onChange={(event): void => handleChange('direction', event.target.checked ? 'rtl' : 'ltr')}
							/>
						}
						label={
							<div>
								RTL
								<Typography color="textSecondary" component="p" variant="caption">
									{t('Change text direction')}
								</Typography>
							</div>
						}
					/>
				</Box>
				<Box
					sx={{
						mt: 2,
						px: 1.5
					}}>
					<FormControlLabel
						control={
							<Switch
								checked={values.responsiveFontSizes}
								color="primary"
								edge="start"
								name="direction"
								onChange={(event): void => handleChange('responsiveFontSizes', event.target.checked)}
							/>
						}
						label={
							<div>
								{t('Responsive font sizes')}
								<Typography color="textSecondary" component="p" variant="caption">
									{t('Adjust font for small devices')}
								</Typography>
							</div>
						}
					/>
				</Box>
				<Box
					sx={{
						mt: 2,
						px: 1.5
					}}>
					<FormControlLabel
						control={
							<Switch
								checked={values.compact}
								color="primary"
								edge="start"
								name="compact"
								onChange={(event): void => handleChange('compact', event.target.checked)}
							/>
						}
						label={
							<div>
								{t('Compact')}
								<Typography color="textSecondary" component="p" variant="caption">
									{t('Fixed width on some screens')}
								</Typography>
							</div>
						}
					/>
				</Box>
				<Box
					sx={{
						mt: 2,
						px: 1.5
					}}>
					<FormControlLabel
						control={
							<Switch
								checked={values.roundedCorners}
								color="primary"
								edge="start"
								name="roundedCorners"
								onChange={(event): void => handleChange('roundedCorners', event.target.checked)}
							/>
						}
						label={
							<div>
								{t('Rounded Corners')}
								<Typography color="textSecondary" component="p" variant="caption">
									{t('Increase border radius')}
								</Typography>
							</div>
						}
					/>
				</Box>
				<Box sx={{ mt: 3 }}>
					<Button color="primary" fullWidth onClick={handleSave} variant="contained">
						{t('Save Settings')}
					</Button>
				</Box>
			</Drawer>
		</>
	)
}

export default SettingsDrawer
