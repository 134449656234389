import { FC } from 'react'
import { useRoutes } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { CssBaseline, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material'
import './i18n'
import RTL from '@components/RTL'
import SettingsDrawer from '@components/SettingsDrawer'
import SplashScreen from '@components/SplashScreen'
import useAuth from '@hooks/useAuth'
import useScrollReset from '@hooks/useScrollReset'
import useSettings from '@hooks/useSettings'
import routes from './routes'
import { createCustomTheme } from './theme'
import { MsalProvider } from '@azure/msal-react'
import { IPublicClientApplication } from '@azure/msal-browser'

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

type AppProps = {
	pca: IPublicClientApplication
}

const App: FC<AppProps> = ({ pca }) => {
	const content = useRoutes(routes)
	const { settings } = useSettings()
	const auth = useAuth()

	useScrollReset()

	const theme = createCustomTheme({
		direction: settings.direction,
		responsiveFontSizes: settings.responsiveFontSizes,
		roundedCorners: settings.roundedCorners,
		theme: settings.theme
	})

	return (
		<MsalProvider instance={pca}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<RTL direction={settings.direction}>
						<CssBaseline />
						<Toaster position="top-center" />
						<SettingsDrawer />
						{auth.isInitialized ? content : <SplashScreen />}
					</RTL>
				</ThemeProvider>
			</StyledEngineProvider>
		</MsalProvider>
	)
}

export default App
