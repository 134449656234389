import http from '../http-common'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { SyncDetails, TotalWorkOrdersGraph, TransportSyncDetails } from '@types'

const responseBody = (response: AxiosResponse) => response.data

const requests = {
	get: (url: string, config?: AxiosRequestConfig) => http.get(url, config).then(responseBody)
}

export const overviewService = {
	getNumberOfWorkOrders: (): Promise<number> => requests.get('/workOrders/count'),
	getTotalWorkOrdersGraphData: (): Promise<TotalWorkOrdersGraph> => requests.get('/workOrders/six-months-graph-data'),
	syncServiceDimensions: (): Promise<any> => requests.get('users/call-sync-service-dimensions'),
	getLastSyncDetails: (): Promise<SyncDetails> => requests.get('syncLogs'),
	getLogDimensionSyncs: (params: any): Promise<any> =>
		requests.get('logDimensionSync/get-log-dimension-syncs', { params: params }),
	getUnprocessedTransportSyncDetails: (): Promise<TransportSyncDetails> =>
		requests.get('/transports/unprocessed-sync-details'),
	syncTransports: (): Promise<any> => requests.get('/transports/call-sync-transports')
}
