import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import { IconButton, TextField } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { FC } from 'react'
import { GridToolbar } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(
	createStyles({
		root: {
			padding: 10,
			justifyContent: 'space-between',
			display: 'flex',
			alignItems: 'flex-start',
			flexWrap: 'wrap'
		}
	})
)

interface QuickSearchToolbarProps {
	clearSearch: () => void
	onChange: () => void
	value: string
	disableSearchFilter?: boolean
}

const QuickSearchToolbar: FC<QuickSearchToolbarProps> = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	return (
		<>
			{!props.disableSearchFilter && (
				<div className={classes.root}>
					<div>
						<GridToolbar></GridToolbar>
					</div>
					<TextField
						variant="standard"
						value={props.value}
						onChange={props.onChange}
						placeholder={t('Search…')}
						InputProps={{
							startAdornment: <SearchIcon fontSize="small" />,
							endAdornment: (
								<IconButton
									title="Clear"
									aria-label="Clear"
									size="small"
									style={{ visibility: props.value ? 'visible' : 'hidden' }}
									onClick={props.clearSearch}>
									<ClearIcon fontSize="small" />
								</IconButton>
							)
						}}
					/>
				</div>
			)}
		</>
	)
}

export default QuickSearchToolbar
