import http from '../http-common'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { User } from '@types'

const responseBody = (response: AxiosResponse) => response.data

const requests = {
	get: (url: string, config?: AxiosRequestConfig) => http.get(url, config).then(responseBody),
	post: (url: string, config: any) =>
		http
			.post(url, config)
			.then(responseBody)
			.catch((error) => {
				return Promise.reject(error)
			}),
	delete: (url: string) => http.delete(url).then(responseBody)
}

export const userService = {
	getUsers: (params: any): Promise<any> => requests.get('users', { params: params }),
	getUserDetails: (params: any): Promise<any> => requests.get('users/get-user', { params }),
	register: (user: User): Promise<void> => requests.post('users/register', user),
	changePassword: (params: any): Promise<void> => requests.post('users/change-password', params),
	editUserDetails: (user: any): Promise<any> => requests.post('users/edit-user', user),
	deleteUser: (params: string): Promise<any> => requests.delete(`users/delete/${params}`)
}
